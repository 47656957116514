@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f2f5f7;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #f2f5f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
  font-family: Verdana;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
  font-family: Verdana;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
}

label {
  margin: 0 0 6px 0;
  font-size: 1.1rem;
}

input {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #777;
  background-color: #eee;
  outline: none;
  font-size: 1.1rem;
  box-sizing: border-box;
  margin: 0 0 8px 0;
}

.error {
  color: red;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #e1e1e1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #1b143b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #402ab2;
}
